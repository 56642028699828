<template>
  <b-table-simple class="table-bordered" responsive="true" hover>
    <b-thead class="thead-default">
      <b-tr>
        <b-th class="align-middle"></b-th>
        <b-th>Với tất cả Ví điện tử</b-th>
        <b-th>Với Ví điện tử đã xác thực</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr>
        <b-td>Số lượng giao dịch xử lý thành công</b-td>
        <b-td>{{ numberFormat(listReport.c_trans_success) }}</b-td>
        <b-td>{{ numberFormat(listReport.c_trans_success) }}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Giá trị giao dịch xử lý thành công</b-td>
        <b-td>{{ numberFormat(listReport.s_trans_success) }}</b-td>
        <b-td>{{ numberFormat(listReport.s_trans_success) }}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Số lượng giao dịch xử lý không thành công</b-td>
        <b-td>{{ numberFormat(listReport.c_trans_fail) }}</b-td>
        <b-td>{{ numberFormat(listReport.c_trans_fail) }}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Giá trị giao dịch không thành công</b-td>
        <b-td>{{ numberFormat(listReport.s_trans_fail) }}</b-td>
        <b-td>{{ numberFormat(listReport.s_trans_fail) }}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Số lượng giao dịch thanh toán, chuyển tiền bằng Ví điện tử</b-td>
        <b-td>{{ numberFormat(listReport.c_payment_transfer) }}</b-td>
        <b-td>{{ numberFormat(listReport.c_payment_transfer) }}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Giá trị giao dịch thanh toán, chuyển tiền bằng Ví điện tử</b-td>
        <b-td>{{ numberFormat(listReport.s_payment_transfer) }}</b-td>
        <b-td>{{ numberFormat(listReport.s_payment_transfer) }}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Số lượng giao dịch nạp tiền vào Ví điện tử</b-td>
        <b-td>{{ numberFormat(listReport.c_deposit) }}</b-td>
        <b-td>{{ numberFormat(listReport.c_deposit) }}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Giá trị giao dịch nạp tiền vào Ví điện tử</b-td>
        <b-td>{{ numberFormat(listReport.s_deposit) }}</b-td>
        <b-td>{{ numberFormat(listReport.s_deposit) }}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Số lượng giao dịch rút tiền từ Ví điện tử</b-td>
        <b-td>{{ numberFormat(listReport.c_withdraw) }}</b-td>
        <b-td>{{ numberFormat(listReport.c_withdraw) }}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Giá trị giao dịch rút tiền từ Ví điện tử</b-td>
        <b-td>{{ numberFormat(listReport.s_withdraw) }}</b-td>
        <b-td>{{ numberFormat(listReport.s_withdraw) }}</b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import Common from "@/core/mixins/common";
export default {
  mixins: [Common],
  name: "ListReport",
  props: ["listReport"],
  data() {
    return {};
  },
};
</script>

<style scoped></style>
