<template>
  <b-table-simple class="table-bordered" responsive="true" hover>
    <b-thead class="thead-default">
      <b-tr>
        <b-th class="align-middle"></b-th>
        <b-th>Game Zone</b-th>
        <b-th>Dịch vụ thanh toán (hóa đơn, VETC...)</b-th>
        <b-th>Tổng cộng</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr>
        <b-td>Số lượng giao dịch thành công</b-td>
        <b-td>{{ numberFormat(listReport.PAYGATE.c_transaction) }}</b-td>
        <b-td>{{ numberFormat(listReport.BILLING.c_transaction) }}</b-td>
        <b-td>{{
          numberFormat(
            listReport.BILLING.c_transaction + listReport.PAYGATE.c_transaction
          )
        }}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Giá trị giao dịch thành công</b-td>
        <b-td>{{ numberFormat(listReport.PAYGATE.s_amount) }}</b-td>
        <b-td>{{ numberFormat(listReport.BILLING.s_amount) }}</b-td>
        <b-td>
          {{
            numberFormat(
              listReport.PAYGATE.s_amount + listReport.BILLING.s_amount
            )
          }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>Số lượng giao dịch thành công với Ví điện tử đã xác thực</b-td>
        <b-td>{{ numberFormat(listReport.PAYGATE.c_transaction) }}</b-td>
        <b-td>{{ numberFormat(listReport.BILLING.c_transaction) }}</b-td>
        <b-td>{{
          numberFormat(
            listReport.BILLING.c_transaction + listReport.PAYGATE.c_transaction
          )
        }}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Giá trị giao dịch thành công với Ví điện tử đã xác thực</b-td>
        <b-td>{{ numberFormat(listReport.PAYGATE.s_amount) }}</b-td>
        <b-td>{{ numberFormat(listReport.BILLING.s_amount) }}</b-td>
        <b-td>
          {{
            numberFormat(
              listReport.PAYGATE.s_amount + listReport.BILLING.s_amount
            )
          }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-td>Số lượng giao dịch thành công với Ví điện tử chưa xác thực</b-td>
        <b-td>0</b-td>
        <b-td>0</b-td>
        <b-td>0</b-td>
      </b-tr>
      <b-tr>
        <b-td>Giá trị giao dịch thành công với Ví điện tử chưa xác thực</b-td>
        <b-td>0</b-td>
        <b-td>0</b-td>
        <b-td>0</b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import Common from "@/core/mixins/common";
export default {
  mixins: [Common],
  name: "ListReportByProduct",
  props: ["listReport"],
  data() {
    return {
      fields: [
        {
          label: "Số lượng giao dịch thành công",
          all: 25,
          auth: 20,
          total: 20,
        },
        {
          label: "Giá trị giao dịch thành công",
          all: 500,
          auth: 400,
          total: 20,
        },
        {
          label: "Số lượng giao dịch thành công với Ví điện tử đã xác thực",
          all: 25,
          auth: 20,
          total: 20,
        },
        {
          label: "Giá trị giao dịch thành công với Ví điện tử đã xác thực",
          all: 500,
          auth: 400,
          total: 20,
        },
        {
          label: "Số lượng giao dịch thành công với Ví điện tử chưa xác thực",
          all: 25,
          auth: 20,
          total: 20,
        },
        {
          label: "Giá trị giao dịch thành công với Ví điện tử chưa xác thực",
          all: 500,
          auth: 400,
          total: 20,
        },
      ],
    };
  },
};
</script>

<style scoped></style>
