<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="mb-6">
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Báo cáo tình hình giao dịch</h6>
              </template>
              <template>
                <div>
                  <div
                      show
                      variant="light"
                      class="alert alert-custom alert-white alert-shadow fade show gutter-b justify-content-end"
                  >
                    <b-col md="3">
                      <b-container fluid>
                        <b-col md="9"><label :for="`date`">Tình hình giao dịch:</label></b-col>
                        <b-col md="9">
                          <b-form-select
                              v-model="selected"
                              :options="options"
                          ></b-form-select>
                        </b-col>
                      </b-container>
                    </b-col>
                    <b-col md="3">
                      <b-container fluid>
                        <b-col md="9"><label :for="`date`">Từ ngày:</label></b-col>
                        <b-col md="9">
                          <b-form-input
                              :id="`type-date`"
                              :type="`date`"
                              v-model="dateFrom"
                          ></b-form-input>
                        </b-col>
                      </b-container>
                    </b-col>

                    <b-col md="3">
                      <b-container fluid>
                        <b-col md="9"><label :for="`date`">Đến ngày:</label></b-col>
                        <b-col md="9">
                          <b-form-input
                              :id="`type-date`"
                              :type="`date`"
                              v-model="dateTo"
                          ></b-form-input>
                        </b-col>
                      </b-container>
                    </b-col>

                    <b-col md="3">
                      <b-col md="9" class="pt-2"><label ></label></b-col>
                      <b-col class="text-right">
                        <b-button-group>
                          <b-button type="button" variant="primary" @click="getReport()">
                            <i class="la la-search"></i>
                            <span>Tìm kiếm</span>
                          </b-button>

                          <b-button type="button" variant="success" @click="exportCsv">
                            <i class="la la-file-export"></i>
                            <span>Xuất file</span>
                          </b-button>
                        </b-button-group>
                      </b-col>
                    </b-col>
                  </div>

                  <!--    <b-card-text v-if="errorMessage" align="center" class="text-danger">{{-->
                  <!--      errorMessage-->
                  <!--    }}</b-card-text>-->

                  <alert-dialogue ref="confirmDialogue"></alert-dialogue>
                </div>
              </template>
              <div v-if="selected === 1 && Object.keys(items).length > 0">
                <ListReport :listReport="items"></ListReport>
              </div>
              <div v-if="selected === 2 && Object.keys(items).length > 0">
                <listReportByProd :listReport="items"></listReportByProd>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ListReport from "@/view/pages/Report/situation/components/listReport.vue";
import listReportByProd from "@/view/pages/Report/situation/components/listReportByProd.vue";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import AlertDialogue from "@/view/pages/components/AlertModal";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
const BiRepository = RepositoryFactory.get("Bi");

// import router from "@/router";
Vue.component("multiselect", Multiselect);

// const TransactionRepository = RepositoryFactory.get("transactions");
export default {
  mixins: [Common],
  data() {
    return {
      items: {},
      itemsSample: {
        BILLING: {
          type: "BILLING",
          c_transaction: 0,
          s_amount: 0,
        },
        PAYGATE: {
          type: "PAYGATE",
          c_transaction: 0,
          s_amount: 0,
        },
      },
      dateFrom: this.getFirstDayOfMonth(),
      dateTo: this.getCurrentDay(),
      selected: 1,
      options: [
        { value: 1, text: "Toàn hệ thống" },
        { value: 2, text: "Theo sản phẩm" },
      ],
    };
  },
  components: {
    ListReport,
    listReportByProd,
    AlertDialogue,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Báo cáo tình hình giao dịch" },
    ]);
  },
  methods: {
    async getReport() {
      try {
        this.$bus.$emit("show-loading", true);
        let params = {
          date_from: this.dateFrom,
          date_to: this.dateTo,
          type: this.selected,
        };
        let response = {};
        response = await BiRepository.getListSituation(params);
        if (response.data.error_code) {
          this.notifyAlert("warn", "Có lỗi khi lấy thông tin");
        }
        this.$bus.$emit("show-loading", false);
        this.items = response.data.data;
        if (this.selected === 2) {
          this.items = this.convertDataReport(this.items);
        } else {
          this.items.c_trans_success = parseInt(this.items.c_trans_success);
          this.items.c_trans_fail = parseInt(this.items.c_trans_fail);
          this.items.c_deposit = parseInt(this.items.c_deposit);
          this.items.c_withdraw = parseInt(this.items.c_withdraw);
          this.items.c_payment_transfer = parseInt(
            this.items.c_payment_transfer
          );
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },
    convertDataReport($data) {
      if (!$data.hasOwnProperty("PAYGATE")) {
        $data.PAYGATE = this.itemsSample.PAYGATE;
      }
      if (!$data.hasOwnProperty("BILLING")) {
        $data.BILLING = this.itemsSample.BILLING;
      }
      return $data;
    },
    async exportCsv() {
      this.$bus.$emit("show-loading", true);
      this.errorMessage = null;
      let self = this;
      return BiRepository.exportSituation({
        date_from: this.dateFrom,
        date_to: this.dateTo,
        type: this.selected,
      })
        .then(async (response) => {
          if (response.status !== 200 || response.data.error_code === 1) {
            this.notifyAlert("warn", response.data.message);
            this.$bus.$emit("show-loading", false);
            return;
          }
          this.$bus.$emit("show-loading", false);

          let link = response.data.data;
          window.open(link, "_blank");
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          return (self.errorMessage = "Có lỗi khi lấy thông tin");
        });
    },
  },
  watch: {
    selected: function (newVal) {
      this.selected = newVal;
      this.items = {};
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
input[type="text"],
input[type="date"],
select {
  min-height: 43px;
}
</style>
